<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Proforma Invoice Uang Muka</h2>
            </CCardHeader>
            <CCardBody>                
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>
                
                <proforma-invoice-down-payment-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :proformaInvoiceDownPaymentClick="proformaInvoiceDownPaymentClick"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import ProfomaInvoiceDownPaymentGrid from '../ProformaInvoiceDownPayment/Grid/ProformaInvoiceDownPaymentGrid.vue';
import proformaInvoiceServices from './Script/ProformaInvoideDownPaymentScript';
import { sweet_alert } from '../../../infrastructure/constant/variable';
import { response } from '../../../infrastructure/constant/response';

export default {
    name: 'ProformaInvoiceDownPayment',
    components: {
        'proforma-invoice-down-payment-grid': ProfomaInvoiceDownPaymentGrid
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Proforma Invoice Uang Muka');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$router.push({ name: 'Proforma Invoice Uang Muka Form', params: {  formtype:'Add', id:' ', view:'new' } });
        },
        editClick(id, view){
            const routeData = this.$router.resolve({ name: 'Proforma Invoice Uang Muka Form', params: { formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await proformaInvoiceServices.getProformaInvoiceQuery(id);
            
            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    this.$loading(true);
                    //Grid Item and Booking
                    var gridItem = proformaInvoiceServices.editItemGridDataGenerator(data.transaction_proforma_invoice_details);
                    var piDetail = proformaInvoiceServices.transactionProformaInvoiceDetail(gridItem, data.tax_percentage);

                    const piData = {
                        proforma_invoice_number: data.proforma_invoice_number,
                        proforma_invoice_date: data.proforma_invoice_date,
                        status: "Delete",
                        project_id: data.project_id,
                        purchase_order_sequence: data.purchase_order_sequence,
                        customer_id: data.customer_id,
                        address: data.address,
                        tax_percentage: data.tax_percentage,
                        tax_amount: data.tax_amount,
                        down_payment_pct: data.down_payment_pct,
                        down_payment: data.down_payment,
                        total: data.total,
                        notes: data.notes,
                        transaction_proforma_invoice_details: piDetail
                    }

                    const variables = {
                        id : id,
                        data : piData
                    } 

                    proformaInvoiceServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });
        },  
        proformaInvoiceDownPaymentClick(id) {
            const routeData = this.$router.resolve({name: 'Proforma Invoice Uang Muka Report Html Template', params: { id:id }});
            window.open(routeData.href, '_blank');
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>